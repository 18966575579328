<template>

  <edit-geofence ref="editGeofenceRef"></edit-geofence>

  <div style="display: flex;justify-content: space-between;align-content: space-between;">
    <div style="width: 30%;"></div>

    <el-input v-model="query" placeholder="Buscar Usuario" style="--el-input-border-radius: 5px;margin-right: 5px;"></el-input>
    <el-tooltip
        effect="dark"
        content="Refrescar Facturas"
        placement="bottom"
    >
      <el-button type="primary" plain @click="loadUserInvoices()"><i class="fas fa-sync"></i></el-button>
    </el-tooltip>
    <el-tooltip
        effect="dark"
        content="Nuevo Usuario"
        placement="bottom"
    >
      <el-button type="primary"><i class="fas fa-user-plus"></i></el-button>
    </el-tooltip>

  </div>

  <div style="border: silver 1px solid; border-radius: 5px;margin-top: 20px;height: calc(100vh - 200px);">
    <div class="device">
      <div class="name" style="font-size: 12px;font-weight: 100;padding: 5px;width: calc(40% + 3.5px)">Nombre</div>
      <div class="pending-invoices" style="font-size: 12px;font-weight: 100;padding: 5px;width: 120px;">Facturas Pendientes</div>
      <div class="pending-balance" style="font-size: 12px;font-weight: 100;padding: 5px;width: 120px;">Saldo Pendiente</div>
      <div class="icons" style="font-size: 12px;font-weight: 100;padding: 5px;">Estado</div>
    </div>
    <div style="overflow-x: hidden;overflow-y: scroll;height: calc(100vh - 230px);">

      <div v-for="(user) in filteredDevices" :key="user.id" class="device">

        <div class="name">{{user.name}}</div>
        <div class="pending-invoices" style="width: 120px;padding: 5px;text-align: center;">{{getPendingInvoices(user.id)}}</div>
        <div class="pending-balance" style="width: 120px;padding: 5px;text-align: center;">{{formatCurrency(getPendingBalance(user.id))}}</div>
        <div class="icons">
          <div :style="{color: user.disabled ? 'red' : 'var(--text-blue)'}">
            <i class="fas" :class="{'fa-user-check': !user.disabled, 'fa-user-slash': user.disabled}"></i>
          </div>
          <div>
            <i class="fas fa-envelope"></i>
          </div>
        </div>
      </div></div>
  </div>

</template>

<script setup>

import {ref, computed, onMounted} from 'vue';
import {useStore} from "vuex"
import EditGeofence from "../tarkan/components/views/edit-geofence";

const store = useStore();

const query = ref('');
const editGeofenceRef = ref(null);
const userInvoices = ref({});

// Función para cargar facturas de todos los usuarios
const loadUserInvoices = () => {
  fetch("/tarkan/invoices/manager").then((response) => {
    response.json().then((json) => {
      // Transformar datos para acceso rápido por ID
      const invoicesMap = {};
      json.forEach(user => {
        invoicesMap[user.id] = {
          pendingCount: user.invoices.filter(inv => ['PENDING', 'OVERDUE'].includes(inv.status)).length,
          pendingBalance: user.invoices
            .filter(inv => ['PENDING', 'OVERDUE'].includes(inv.status))
            .reduce((acc, inv) => acc + inv.value, 0)
        };
      });
      userInvoices.value = invoicesMap;
    });
  });
};

// Obtener cantidad de facturas pendientes para un usuario
const getPendingInvoices = (userId) => {
  if (userInvoices.value[userId]) {
    return userInvoices.value[userId].pendingCount;
  }
  return 0;
};

// Obtener saldo pendiente para un usuario
const getPendingBalance = (userId) => {
  if (userInvoices.value[userId]) {
    return userInvoices.value[userId].pendingBalance;
  }
  return 0;
};

// Formatear moneda
const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
};

// Asegúrate de que estamos usando datos de usuarios
const filteredDevices = computed(()=>{
  // Si el estado no incluye usuarios, usa un array vacío para evitar errores
  const userList = store.state.users?.userList || [];
  
  if(query.value === ''){
    return userList;
  }else {
    return userList.filter((d) => {
      return d.name && d.name.toLowerCase().match(query.value.toLowerCase());
    });
  }
})

// Al montar el componente, cargar los datos de usuarios
onMounted(() => {
  store.dispatch('users/load').then(() => {
    loadUserInvoices();
  });
})



</script>

<style scoped>
.device{
  border-bottom: silver 1px solid;
  display: flex;
  flex-direction: row;
  text-align: center;
  cursor: pointer;
  margin-right: -1px;
}
.device:first-child{
  background: rgba(0,0,0,0.03)
}

.device:hover{
  background: rgba(0,0,0,0.03);
}



.device .name{
  font-size: 12px;
  padding: 7px;
  text-align: left;
  line-height: 14px;
  font-weight: 800;
  border-right: silver 1px dotted;
  width: 40%;
}

.device .pending-invoices, .device .pending-balance {
  font-size: 12px;
  padding: 7px;
  text-align: center;
  line-height: 14px;
  font-weight: 800;
  border-right: silver 1px dotted;
  width: 120px;
}

.icons{
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  flex: 1;
  font-size: 11px;
}

.icons div{
  display: flex;
  justify-content: center;
  flex: 1;
  border-right: silver 1px dotted;
  padding: 7px;
  font-size: 11px;
}
.icons div i{
  font-size: 14px;
}

.icons div:first-child{
  border-right: none;
}

.icons div span{
  display: flex;
  padding: 2px;
  padding-left: 5px;
}

.subtitle{
  margin-top: 20px;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--text-black);
}

.subtitle i{
  font-size: 12px;
  margin-right: 3px;
}

.updated{
  font-size: 12px;
  margin-top: 5px;
  color: var(--text-silver);
}

.address{
  color: var(--text-blue);
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  line-height: 20px;
}

</style>