<template>
  <!-- Select de dispositivos -->
  <el-select v-model="formData"
             @change="addCar($event)" :value-key="'id'" filterable :placeholder="KT('device.devices')" :size="'large'" :no-match-text="KT('NO_MATCH_TEXT')" :no-data-text="KT('NO_DATA_TEXT')">
    <el-option
        v-for="item in store.state.devices.deviceList"
        :key="item.id"
        :label="getLabel(item)"
        :value="item.id"
        :style="item.attributes.commandId ? '' : 'background-color: #f8d7da;'">
    </el-option>
  </el-select>

  <!-- Lista de dispositivos seleccionados con scroll -->
  <div style="border: silver 1px solid; border-radius: 5px; margin-top: 10px; max-height: calc(70vh - 200px); overflow-y: auto;">
    <div v-for="(device, k) in selected" style="display: flex; border-bottom: silver 1px dotted;" :key="device.id" :style="device.attributes.commandId ? '' : 'background-color: #f8d7da;'">
      <div style="padding: 7px; flex: 1;">
        {{ device.name }} - {{ device.uniqueId }} - {{ device.attributes.placa }} 
        {{ device.attributes.commandId ? '' : '(Sin CommandId)' }}
      </div>
      <div style="padding: 5px; padding-right: 10px; font-size: 18px;" @click="remove(k)">
        <i class="fas fa-minus-square"></i>
      </div>
    </div>
  </div>

  <!-- Botón para agregar todos los dispositivos -->
  <el-button type="primary" @click="addAllDevices">
    Adicionar Todos
  </el-button>
  <!-- Botón para generar el PDF -->
  <el-button 
    type="success" 
    @click="generatePDF" 
    :loading="loading" 
    :disabled="loading">
    <span v-if="!loading">Gerar PDF</span>
    <span v-else>Cargando...</span> <!-- Texto alternativo cuando está cargando -->
  </el-button>

  <!-- Mostrar las cantidades fuera del botón -->
  <div style="margin-top: 10px;">
    <span>{{ devicesWithCommandId }} con CommandId</span>
    <span style="margin-left: 20px;">{{ devicesWithoutCommandId }} sin CommandId</span>
  </div>

  
</template>

<script setup>
import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/tab-pane/style/css'
import 'element-plus/es/components/tabs/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/checkbox/style/css'

import { ElSelect, ElOption, ElButton } from "element-plus";
import { ref, defineExpose, computed } from 'vue'
import { useStore } from 'vuex';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Importar la librería autoTable

const store = useStore();

const formData = ref(null);
const selected = ref([]);

// Función para limpiar la lista de seleccionados
const clear = () => {
  selected.value = [];
}

// Exponer la función para limpiar
defineExpose({
  selected, clear
})

// Función para eliminar un dispositivo de la lista de seleccionados
const remove = (key) => {
  selected.value.splice(key, 1);
}

// Función para generar la etiqueta del dispositivo con más datos
const getLabel = (device) => {
  return `${device.name} - ${device.uniqueId} - ${device.attributes.placa} ${device.attributes.commandId ? '' : '(Sin CommandId)'}`;
};

// Función para agregar un dispositivo a la lista de seleccionados
const addCar = (car) => {
  const check = selected.value.find((c)=> c.id === car);
  if(!check) {
    const device = store.getters["devices/getDevice"](car);
    if (device) {
      selected.value.push(device);
    }
  }
  formData.value = null;
}

// Función para agregar todos los dispositivos a la lista de seleccionados
const addAllDevices = () => {
  const allDevices = store.state.devices.deviceList;
  if (allDevices && typeof allDevices === 'object') {
    const deviceArray = Object.values(allDevices);
    deviceArray.forEach(device => {
      if (!selected.value.find(d => d.id === device.id)) {
        selected.value.push(device);
      }
    });
  }
};

const devicesWithCommandId = computed(() => {
  return selected.value.filter(device => device.attributes.commandId).length;
});

const devicesWithoutCommandId = computed(() => {
  return selected.value.filter(device => !device.attributes.commandId).length;
});

const loading = ref(false);


// Función para generar el PDF
const generatePDF = () => {
  loading.value = true; // Establecer el estado a "cargando"

  // Crear un nuevo documento PDF
  const doc = new jsPDF();

  // Agregar título
  doc.setFontSize(12); // Tamaño de fuente ajustado para que sea más pequeño
  doc.text('Dispositivos sin CommandId', 10, 10);

  // Definir las cabeceras de la tabla
  const columns = [
    { title: 'ID', dataKey: 'id' },
    { title: 'Nombre', dataKey: 'name' },
    { title: 'IMEI', dataKey: 'uniqueId' },
    { title: 'Placa', dataKey: 'placa' }
  ];

  // Crear los datos de la tabla (solo dispositivos sin CommandId)
  const data = selected.value
    .filter(device => !device.attributes.commandId)
    .map(device => ({
      id: device.id,
      name: device.name,
      uniqueId: device.uniqueId,
      placa: device.attributes.placa
    }));

  // Generar la tabla en el PDF
  doc.autoTable({
    head: [columns.map(col => col.title)], // Cabeceras
    body: data.map(device => [device.id, device.name, device.uniqueId, device.placa]), // Datos
    startY: 20, // Iniciar la tabla en la posición 20
    theme: 'grid', // Utilizar el estilo de cuadrícula
    styles: {
      fontSize: 8, // Reducir el tamaño de la fuente
      cellPadding: 2, // Reducir el espacio entre celdas
      halign: 'center', // Alinear el texto al centro
    },
    headStyles: {
      fontSize: 8, // Cabeceras con un tamaño de fuente pequeño
      fillColor: [255, 255, 255], // Sin fondo para las cabeceras
      textColor: [0, 0, 0], // Color del texto de las cabeceras (negro)
      fontStyle: 'bold', // Cabeceras en negrita
    },
    margin: { top: 10, left: 10, right: 10 },
    //didDrawPage: function (data) {
      // Aquí se pueden agregar más elementos de la página si es necesario
    //},
  });

  // Guardar el PDF con un nombre
  doc.save('dispositivos_sin_commandId.pdf');

  loading.value = false; // Desactivar el estado de carga cuando se haya generado el PDF
};


</script>