<template>
  <el-dialog :lock-scroll="true" v-model="show">
    <template v-slot:title>
      <div style="border-bottom: #e0e0e0 1px solid; padding: 20px;">
        <div class="modal-title">{{ title }}</div>
      </div>
    </template>
    <template v-slot:footer>
      <div style="border-top: #e0e0e0 1px solid; padding: 20px; display: flex; justify-content: space-between">
        <div><el-button type="danger" @click="doDelete()">{{ KT('delete') }}</el-button></div>
        <div>
          <el-button type="danger" plain @click="doCancel()">{{ KT('cancel') }}</el-button>
          <el-button type="primary" @click="doSave()" :disabled="!canSave">{{ KT('save') }}</el-button>
        </div>
      </div>
    </template>

    <el-tabs v-model="tab">
      <el-tab-pane :label="KT('geofence.geofences')" name="first">
        <el-form label-width="120px" label-position="top">
          <el-form-item :label="KT('geofence.name')">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item :label="KT('geofence.type')">
            <el-button-group size="large">
              <el-button type="primary" :plain="!(formData.type === 'LINESTRING')" @click="setType('LINESTRING')"
                :disabled="isEditing && formData.type !== 'LINESTRING'">{{ KT('geofence.line') }}</el-button>
              <el-button type="primary" :plain="!(formData.type === 'CIRCLE')" @click="setType('CIRCLE')"
                :disabled="isEditing && formData.type !== 'CIRCLE'">{{ KT('geofence.circle') }}</el-button>
              <el-button type="primary" :plain="!(formData.type === 'POLYGON')" @click="setType('POLYGON')"
                :disabled="isEditing && formData.type !== 'POLYGON'">{{ KT('geofence.polygon') }}</el-button>
            </el-button-group>
          </el-form-item>

          <div style="display: flex; flex-direction: row;">
            <div style="flex: 1;">
              <el-form-item :label="KT('geofence.totalArea')">
                <el-input disabled :value="store.getters['geofences/getTotalArea']"></el-input>
              </el-form-item>
            </div>
            <div style="padding-top: 35px; margin-left: 10px;">
              <el-button type="primary" @click="doEditArea()" :disabled="isEditing && !canEdit">{{
    KT('geofence.editArea')
  }}</el-button>
            </div>
          </div>
        </el-form>
      </el-tab-pane>

      <el-tab-pane :label="KT('geofence.attributes')" name="attributes">
        <tab-attributes v-model="formData.attributes"></tab-attributes>
      </el-tab-pane>

      <!-- Nueva pestaña para Bloqueos -->
      <el-tab-pane :label="KT('geofence.switches')" name="lockings">
        <el-form>
          <!-- Switches para lockOnEnter y lockOnExit -->
          <el-form-item :label="KT('geofence.switches')">
            <div class="switch-container">
              <div class="switch-item">
                <span>{{ KT('geofence.lockOnEnter') }}</span>
                <el-switch v-model="formData.attributes.lockOnEnter" active-text="On" inactive-text="Off"></el-switch>
              </div>
              <div class="switch-item">
                <span>{{ KT('geofence.lockOnExit') }}</span>
                <el-switch v-model="formData.attributes.lockOnExit" active-text="On" inactive-text="Off"></el-switch>
              </div>
            </div>
          </el-form-item>

          <!-- Selector de velocidad -->
          <el-form-item :label="KT('geofence.speedLimit')">
            <div class="speed-limit-container">
              <el-input-number v-model="formData.attributes.speedLimit" :min="0" :max="130" label="Speed Limit"
                class="speed-input" />
              <el-slider v-model="formData.attributes.speedLimit" :min="0" :max="130" class="speed-slider" />
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="KT('editAttribute.attributes_Devices')" :name="KT('editAttribute.attributes_Devices')">
        <tab-devices ref="deviceList"></tab-devices>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script setup>
import 'element-plus/es/components/input/style/css';
import 'element-plus/es/components/button/style/css';
import 'element-plus/es/components/switch/style/css';
import 'element-plus/es/components/select/style/css';
import 'element-plus/es/components/option/style/css';
import 'element-plus/es/components/dialog/style/css';
import 'element-plus/es/components/tab-pane/style/css';
import 'element-plus/es/components/tabs/style/css';
import 'element-plus/es/components/message/style/css';
import 'element-plus/es/components/checkbox/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/input-number/style/css';
import 'element-plus/es/components/slider/style/css';

import { ElDialog, ElTabs, ElMessageBox, ElNotification, ElTabPane, ElForm, ElFormItem, ElButton, ElInput, ElSwitch, ElInputNumber, ElSlider } from 'element-plus';
import TabAttributes from './tab-attributes';
import TabDevices from "./tab-devices";

const defaultGeofenceData = {
  name: '',
  type: 'CIRCLE',
  attributes: {
    lockOnEnter: false,
    lockOnExit: false,
    speedLimit: 0
  }
};

const defaultTraccarGeofenceData = {
  id: 0,
  name: '',
  description: '',
  area: '',
  calendarId: 0,
  attributes: {}
};

import { ref, defineExpose, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const show = ref(false);


const deviceList = ref(null);

const tab = ref('first');
const title = ref('');
const formData = ref({ ...defaultGeofenceData });
const isEditing = ref(false);

const canEdit = computed(() => !isEditing.value || formData.value.type === formData.value.type);
const canSave = computed(() => formData.value.name.trim() !== '');

import KT from '../../func/kt.js';

const doDelete = () => {
  ElMessageBox.confirm(KT('geofence.deleteConfirm'), KT('sure')).then(() => {
    store.dispatch('geofences/delete', formData.value.id).then(() => {
      show.value = false;
      ElNotification({
        title: 'Info',
        message: KT('success'),
        type: 'info',
      });
    });
  });
};

const newGeofence = () => {
  title.value = KT('geofence.new');
  formData.value = { ...defaultGeofenceData };
  isEditing.value = false;
  show.value = true;
};

const editGeofence = (geofence) => {
  title.value = KT('geofence.edit');
  formData.value = { ...defaultGeofenceData, ...geofence };
  const area = getAreaParsed(geofence.area);
  formData.value.type = area.type;
  store.commit('geofences/setParams', area.params);
  isEditing.value = true;
  show.value = true;
};

defineExpose({
  newGeofence,
  editGeofence
});

const doEditArea = () => {
  store.dispatch('geofences/enableEditing', formData.value.type);
};

const doCancel = () => {
  show.value = false;
};

const fenceAreaCircle = /\((.*?) (.*?),(.*?)\)/i;
const fenceAreaPolygon = /(\s?([-\d.]*)\s([-\d.]*),?)/gm;
const fenceAreaLinestring = /(\s?([-\d.]*)\s([-\d.]*),?)/gm;

const getAreaParsed = (a) => {
  const type = a.split('(')[0].trim();
  if (type === 'LINESTRING') {
    const linestring = a.match(fenceAreaLinestring);
    let tmp = [];
    linestring.forEach((L) => {
      const S = L.trim().replace(',', '').split(' ');
      if (S.length === 2) {
        tmp.push(S);
      }
    });
    return { type: 'LINESTRING', params: tmp };
  } else if (type === 'POLYGON') {
    const polygon = a.match(fenceAreaPolygon);
    let tmp = [];
    polygon.forEach((L) => {
      const S = L.trim().replace(',', '').split(' ');
      if (S.length === 2) {
        tmp.push(S);
      }
    });
    return { type: 'POLYGON', params: tmp };
  } else if (type === 'CIRCLE') {
    const circle = fenceAreaCircle.exec(a);
    if (circle) {
      return { type: 'CIRCLE', params: [circle[1], circle[2], circle[3]] };
    }
  }
  return { type };
};






const getParsedArea = () => {
  const type = formData.value.type;
  const params = store.state.geofences.mapPointEditingParams;

  if (type === 'CIRCLE') {
    return `CIRCLE (${params[0]} ${params[1]}, ${params[2]})`;
  } else if (type === 'LINESTRING') {
    let tmp = 'LINESTRING (';
    params.forEach((p) => {
      tmp += `${p[0]} ${p[1]}, `;
    });
    tmp = tmp.slice(0, -2); // Remove the trailing comma and space
    tmp += ')';
    return tmp;
  } else if (type === 'POLYGON') {
    let tmp = 'POLYGON((';
    params.forEach((p) => {
      tmp += `${p[0]} ${p[1]}, `;
    });
    tmp = tmp.slice(0, -2); // Remove the trailing comma and space
    tmp += '))';
    return tmp;
  }
};

const doSave = () => {
  const tmp = { ...defaultTraccarGeofenceData };
  tmp.id = formData.value.id;
  tmp.name = formData.value.name;
  tmp.area = getParsedArea();

  // Filtrar los atributos para que solo se guarden los valores verdaderos o mayores que cero
  const filteredAttributes = {};
  Object.keys(formData.value.attributes).forEach(key => {
    const value = formData.value.attributes[key];
    if (value !== false && (key !== 'speedLimit' || value > 0)) {
      filteredAttributes[key] = value;
    }
  });
  tmp.attributes = filteredAttributes;

  if (tmp.name.trim() === '') {
    ElMessageBox.confirm(KT('geofence.errors.FILL_NAME'), KT('ops')).then(() => { });
  } else {
    store.dispatch('geofences/save', tmp).then(async (data) => {



      const selectedDevices = deviceList.value.selected;

      if (selectedDevices.length > 0) {
        for (let device of selectedDevices) {

          console.log(device);

          ElNotification({
            title: 'Info',
            message: 'Vinculando atributo ao dispositivo ' + device.name,
            type: 'info',
          });

          await window.$traccar.linkObjects({ deviceId: device.id, geofenceId: data.id });

        }
      }



      show.value = false;
    });
  }
};

const setType = (type) => {
  if (!isEditing.value || formData.value.type === type) {
    formData.value.type = type;
  }
};
</script>

<style>
.el-button-group {
  display: flex !important;
}

.el-button-group .el-button--large {
  flex: 1 !important;
}

.el-select.el-select--large {
  width: 100%;
}

.el-dialog__header,
.el-dialog__body,
.el-dialog__footer {
  padding: 0px !important;
}

.el-dialog__footer {
  margin-top: 20px;
}

.el-tabs__nav-wrap {
  padding-left: 20px;
  padding-right: 20px;
}

.el-tabs__content {
  padding-left: 20px;
  padding-right: 20px;
}

.switch-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.switch-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.isCar {
  border: #05a7e3 1px solid !important;
}

.dialog-header {
  border-bottom: #e0e0e0 1px solid;
  padding: 20px;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
}

.switch-container {
  display: flex;
  flex-direction: column;
  /* Cambiado de row a column para alinear los switches verticalmente */
  gap: 10px;
  /* Espacio entre los switches */
}

.switch-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.speed-limit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  /* Ajustar el ancho según sea necesario */
}

.speed-input {
  width: 100%;
  margin-bottom: 8px;
  /* Espacio entre InputNumber y Slider */
}

.speed-slider {
  width: 100%;
}
</style>
<style>
.el-button-group {
  display: flex !important;
}

.el-button-group .el-button--large {
  flex: 1 !important;
}

.el-select.el-select--large {
  width: 100%;
}

.el-dialog__header,
.el-dialog__body,
.el-dialog__footer {
  padding: 0px !important;
}

.el-dialog__footer {
  margin-top: 20px;
}

.el-tabs__nav-wrap {
  padding-left: 20px;
  padding-right: 20px;
}

.el-tabs__content {
  padding-left: 20px;
  padding-right: 20px;
}

.switch-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.switch-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.speed-limit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
}

.speed-input {
  width: 100%;
  margin-bottom: 8px;
}

.speed-slider {
  width: 100%;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
}
</style>